import React from "react";
import styles from "./StackedBarChart.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
} from "recharts";

const StackedBarChart = (props) => {
  let chartWidth = props.width ? props.width : "100%";
  let axes =
    props.layout === "vertical" ? (
      <>
        <XAxis type="number" style={{ opacity: "0" }} />
        <YAxis type="category" dataKey="name" />
      </>
    ) : (
      <>
        <XAxis dataKey="name" />
        <YAxis />
      </>
    );

  return (
    <BarChart
      width={chartWidth}
      height={chartWidth * 0.3}
      layout={props.layout === "vertical" ? "vertical" : "horizontal"}
      data={props.data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      {axes}
      <Tooltip />
      <Bar dataKey="Idle" stackId="a" fill="#d43939" />
      <Bar dataKey="Active" stackId="a" fill="#41c487">
        <LabelList dataKey="activePercentage" style={{ fill: "white" }} />
      </Bar>
    </BarChart>
  );
};

export default StackedBarChart;
