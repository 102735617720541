import React from "react";
import styles from "./SidebarTab.module.css";
import { Link } from "react-router-dom";

const SidebarTab = (props) => {
  const labelText = props.label ? props.label : "";
  let isActiveClass = props.active ? styles.active : "";

  return (
    <div
      className={`${styles.sidebarTab} ${isActiveClass}`}
      onClick={() => props.onClick()}
    >
      <Link to={props.linkTo}>{labelText}</Link>
    </div>
  );
};

export default SidebarTab;
