import React, { Component } from "react";
import axios from "axios";
import * as env from "../../store/environment/environment";
import { getQueryParams } from "../../utilities/generalUtility";

const withAWSAuth = (WrappedComponent) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? env.DEV_APP_BASE_URL
      : env.APP_BASE_URL;

  return class extends Component {
    state = {
      loggedIn: false,
    };

    async componentDidMount() {
      let userInfo = await this.getUserInfo();
      if (userInfo === true) {
        this.setState({ loggedIn: true });
        let params = getQueryParams();
        if (params.code !== undefined) {
          window.location.replace(baseUrl);
        }
      } else {
        window.location.replace(
          "https://auth.nnmcdonald-portfolio.com/login?client_id=3rave2fhkoh0eis8ue06avkio7&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" +
            baseUrl
        );
      }
    }

    getAuthData = async (authCode) => {
      const params = new URLSearchParams();
      params.append("grant_type", "authorization_code");
      params.append("client_id", "3rave2fhkoh0eis8ue06avkio7");
      params.append("code", authCode);
      params.append("redirect_uri", baseUrl);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      try {
        const response = await axios.post(
          "https://auth.nnmcdonald-portfolio.com/oauth2/token",
          params,
          config
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    };

    getUserInfo = async () => {
      try {
        let token = localStorage.getItem("token");
        let expires = localStorage.getItem("expiresAt");
        if (
          token === null ||
          token === undefined ||
          expires === null ||
          expires === undefined ||
          new Date(expires) < Date.now()
        ) {
          const params = getQueryParams();
          if (params.code !== undefined) {
            let authData = await this.getAuthData(params.code);
            if (authData.id_token) {
              let millisecondsUntilPasswordExpire =
                parseInt(authData.expires_in) * 1000;
              let currentTime = new Date();
              let expiredTime = new Date(
                currentTime.getTime() + millisecondsUntilPasswordExpire
              );
              localStorage.setItem("token", authData.id_token);
              localStorage.setItem("expiresAt", expiredTime);
              return true;
            } else return false;
          } else return false;
        } else return true;
      } catch (err) {
        console.log("Error: " + err);
      }
    };

    render() {
      if (this.state.loggedIn) {
        return <WrappedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  };
};

export default withAWSAuth;
