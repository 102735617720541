import React from "react";
import styles from "./BarChart.module.css";
import ChartWrapper from "../ChartWrapper/ChartWrapper";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
} from "recharts";

const BarChart = (props) => {
  let chartWidth = props.width ? props.width : "100%";
  let axes =
    props.layout === "vertical" ? (
      <>
        <XAxis type="number" style={{ opacity: "0" }}/>
        <YAxis type="category" dataKey="name" />
      </>
    ) : (
      <>
        <XAxis dataKey="name" />
        <YAxis />
      </>
    );

  return (
    <ChartWrapper label={props.label}>
      <ResponsiveContainer width={chartWidth} height={chartWidth * 0.6}>
        <RechartsBarChart
          width={chartWidth}
          height={chartWidth * 0.5}
          data={props.data}
          layout={props.layout === "vertical" ? "vertical" : "horizontal"}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {axes}
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="amt" fill="#4278db">
            <LabelList dataKey="amt" style={{ fill: "white" }} />
          </Bar>
        </RechartsBarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default BarChart;
