import React, { useContext } from "react";
import { DasherDataContext } from "../../store/DasherDataContext";
import styles from "./Overview.module.css";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";
import PieChart from "../../components/Chart/PieChart/PieChart";
import BarChart from "../../components/Chart/BarChart/BarChart";
import StackedBarChart from "../../components/Chart/StackedBarChart/StackedBarChart";

const Overview = () => {
  const dasherDataCtx = useContext(DasherDataContext);
  const [windowWidth, windowHeight] = useWindowSize();

  let overviewContent = () => {
    let basePayPercentage =
      (dasherDataCtx.dasherAnalytics.basePaySum /
        dasherDataCtx.dasherAnalytics.grossSum) *
      100;
    let peakPayPercentage =
      (dasherDataCtx.dasherAnalytics.peakPaySum /
        dasherDataCtx.dasherAnalytics.grossSum) *
      100;
    let tipPercentage =
      (dasherDataCtx.dasherAnalytics.tipPaySum /
        dasherDataCtx.dasherAnalytics.grossSum) *
      100;

    // const payPercentageData = [
    //   { name: "Base Pay", value: basePayPercentage },
    //   { name: "Peak Pay", value: peakPayPercentage },
    //   { name: "Tips", value: tipPercentage },
    // ];

    const payPercentageData = [
      { name: "Base Pay", value: dasherDataCtx.dasherAnalytics.basePaySum },
      { name: "Peak Pay", value: dasherDataCtx.dasherAnalytics.peakPaySum },
      { name: "Tips", value: dasherDataCtx.dasherAnalytics.tipPaySum },
    ];

    const hourlyData = [
      {
        name: "Hourly Low",
        amt: Math.round(dasherDataCtx.dasherAnalytics.hourlyLow * 100) / 100,
      },
      {
        name: "Hourly High",
        amt: Math.round(dasherDataCtx.dasherAnalytics.hourlyHigh * 100) / 100,
      },
      {
        name: "Hourly Avg",
        amt: Math.round(dasherDataCtx.dasherAnalytics.hourlyAvg * 100) / 100,
      },
    ];

    let activeHours =
      Math.round(dasherDataCtx.dasherAnalytics.activeHoursSum * 100) / 100;
    let idleHoursSum =
      Math.round(
        (dasherDataCtx.dasherAnalytics.totalHoursSum - activeHours) * 100
      ) / 100;

    const idleHoursData = [
      {
        name: "Idle vs. Active Hours",
        Active: activeHours,
        Idle: idleHoursSum,
        activePercentage:
        'Active Hours ' + ((activeHours / dasherDataCtx.dasherAnalytics.totalHoursSum) * 100).toFixed(2) + '%',
      },
    ];

    return (
      <div>
        <div className={styles.statsRow}>
          <div className={styles.detailBlock}>
            <div className={styles.statWrapper}>
              <span>Gross</span>
              <span>${dasherDataCtx.dasherAnalytics.grossSum.toFixed(2)}</span>
            </div>
            <div className={styles.statWrapper}>
              <span>Total Hours</span>
              <span>
                {dasherDataCtx.dasherAnalytics.totalHoursSum.toFixed(2)}
              </span>
            </div>
            <div className={styles.statWrapper}>
              <span>Total Mileage</span>
              <span>{dasherDataCtx.dasherAnalytics.mileageSum.toFixed(2)}</span>
            </div>
          </div>
          <PieChart
            label=""
            data={payPercentageData}
            width={windowWidth > 992 ? windowWidth * 0.4 : windowWidth * 0.8}
          />
        </div>
        <div className={styles.statsRow}>
          <div>
            <div className={styles.detailBlock}>
              <div className={styles.statWrapper}>
                <span>Idle Hours</span>
                <span>{idleHoursSum.toFixed(2)}</span>
              </div>
              <div className={styles.statWrapper}>
                <span>Active Hours</span>
                <span>{activeHours.toFixed(2)}</span>
              </div>
            </div>
            <StackedBarChart
              data={idleHoursData}
              layout="vertical"
              width={windowWidth > 992 ? windowWidth * 0.4 : windowWidth * 0.8}
            />
          </div>
          <BarChart
            label=""
            layout="vertical"
            data={hourlyData}
            width={windowWidth > 992 ? windowWidth * 0.4 : windowWidth * 0.8}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <h1>Overview</h1>
      {dasherDataCtx.dasherAnalytics ? overviewContent() : ""}
    </div>
  );
};

export default Overview;
