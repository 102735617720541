import React, { useState } from "react";
import styles from "./ListItem.module.css";

const ListItem = (props) => {
  const [showDeliveries, setShowDeliveries] = useState(false);

  let deliveries = [];
  for (const deliveryIndex in props.shiftData.deliveries) {
    let delivery = props.shiftData.deliveries[deliveryIndex];
    deliveries.push(
      <div key={deliveryIndex} className={styles.deliveriesDetails}>
        <div className={styles.deliveryWrapper}>
          <span>Restaurant: {delivery[0]}</span>
          <span>Base Pay: {delivery[1]}</span>
          <span>Peak Pay: {delivery[2]}</span>
          <span>Tip: {delivery[3]}</span>
          <span>Delivery Gross: {delivery[4]}</span>
        </div>
      </div>
    );
  }

  const itemClickHandler = (event) => {
    setShowDeliveries((prevState) => !prevState);
  };

  return (
    <div className={styles.listItem} onClick={itemClickHandler}>
      <div className={styles.shiftDetails}>
        <span>{props.shiftData.date}</span>
        {/* <span>{props.shiftData.start_location}</span>
        <span>{props.shiftData.end_location}</span> */}
        <span>{props.shiftData.miles.toFixed(2)}</span>
        <span>{props.shiftData.total_hours.toFixed(2)}</span>
        <span>{props.shiftData.active_hours.toFixed(2)}</span>
        <span>{props.shiftData.hourly.toFixed(2)}</span>
        <span>{props.shiftData.gross.toFixed(2)}</span>
      </div>
      {showDeliveries ? deliveries : ""}
    </div>
  );
};

export default ListItem;
