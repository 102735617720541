import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import FormField from "../../components/Form/FormField/FormField";
import NumericFormField from "../../components/Form/FormField/NumericFormField";
import useDeliveryInput from "../../hooks/useFormInput/useFormInput";
import { DASHEBOARD_API_BASE_URL } from "../../store/environment/environment";
import styles from "./AddShiftEntry.module.css";

const AddShiftEntry = () => {
  const textInputValidation = (value) => value.trim() !== "";
  const navigate = useNavigate();

  const getDeliveryInputs = () => {
    return {
      inputs: {
        restaurant: "",
        base: 0,
        peak: 0,
        tip: 0,
      },
      errors: {
        restaurant: null,
        base: null,
      },
    };
  };

  const initialDeliveriesState = [getDeliveryInputs()];

  const [deliveries, setDeliveries] = useState(initialDeliveriesState);
  const dateState = useDeliveryInput(textInputValidation);
  const startLocation = useDeliveryInput();
  const endLocation = useDeliveryInput();
  const miles = useDeliveryInput(textInputValidation);
  const totalHours = useDeliveryInput(textInputValidation);
  const activeHours = useDeliveryInput(textInputValidation);

  const anotherDeliveryHandler = (event) => {
    setDeliveries((prevState) => [...prevState, getDeliveryInputs()]);
  };

  const restaurantChangeHandler = (event, inputIndex) => {
    setDeliveries((prevState) => {
      let updatedState = [...prevState];
      updatedState[inputIndex].inputs.restaurant = event.target.value;
      return updatedState;
    });
  };

  const basePayChangeHandler = (event, inputIndex) => {
    setDeliveries((prevState) => {
      let updatedState = [...prevState];
      updatedState[inputIndex].inputs.base = parseFloat(event.target.value);
      return updatedState;
    });
  };

  const peakPayChangeHandler = (event, inputIndex) => {
    setDeliveries((prevState) => {
      let updatedState = [...prevState];
      updatedState[inputIndex].inputs.peak = parseFloat(event.target.value);
      return updatedState;
    });
  };

  const tipChangeHandler = (event, inputIndex) => {
    setDeliveries((prevState) => {
      let updatedState = [...prevState];
      updatedState[inputIndex].inputs.tip = parseFloat(event.target.value);
      return updatedState;
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    let shiftGross = 0;
    let deliveriesList = deliveries.map((delivery) => {
      let deliveryGross =
        delivery.inputs.base + delivery.inputs.peak + delivery.inputs.tip;
      shiftGross += deliveryGross;

      return [
        delivery.inputs.restaurant,
        delivery.inputs.base,
        delivery.inputs.peak,
        delivery.inputs.tip,
        deliveryGross,
      ];
    });

    let payload = {
      order_id: Date.now().toString(),
      username: "mutedpizza",
      active_hours: parseFloat(activeHours.value),
      deliveries: deliveriesList,
      date: dateState.value,
      hourly: shiftGross / parseFloat(totalHours.value),
      gross: shiftGross,
      total_hours: parseFloat(totalHours.value),
      miles: parseFloat(miles.value),
      start_location: startLocation.value,
      end_location: endLocation.value,
    };

    console.log(payload);
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      let response = await axios.put(DASHEBOARD_API_BASE_URL, payload, config);
      if (response.status === 200) {
        navigate("/", { replace: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  let form = (
    <form onSubmit={submitHandler}>
      <div className={styles["form__controls"]}>
        <FormField
          type="date"
          fieldState={dateState}
          label="Date"
          errorMsg="Date is required"
        />
        <FormField
          type="text"
          fieldState={startLocation}
          label="Start Location"
          errorMsg=""
        />
        <FormField
          type="text"
          fieldState={endLocation}
          label="End Location"
          errorMsg=""
        />
        <NumericFormField
          fieldState={miles}
          label="Miles"
          errorMsg="Mileage is required"
        />
        <NumericFormField
          fieldState={totalHours}
          label="Total Hours"
          errorMsg="Total hours are required"
        />
        <NumericFormField
          fieldState={activeHours}
          label="Active Hours"
          errorMsg="Active Hours are required"
        />
        {deliveries.map((delivery, index) => (
          <div key={index}>
            <h2>Delivery {index + 1}</h2>
            <div className={styles["delivery__control"]}>
              <label>Restaurant</label>
              <input
                className={
                  delivery.errors.restaurant ? styles["invalid-input"] : ""
                }
                type="text"
                value={delivery.inputs.restaurant}
                onChange={(event) => restaurantChangeHandler(event, index)}
              />
              {delivery.errors.restaurant ? <p>Restaurant is required</p> : ""}
            </div>
            <div className={styles["delivery__control"]}>
              <label>Base Pay</label>
              <input
                className={delivery.errors.base ? styles["invalid-input"] : ""}
                type="number"
                step="0.01"
                min="0"
                value={delivery.inputs.base}
                onChange={(event) => basePayChangeHandler(event, index)}
              />
              {delivery.errors.base ? <p>Base pay is required</p> : ""}
            </div>
            <div className={styles["delivery__control"]}>
              <label>Peak Pay</label>
              <input
                type="number"
                step="0.01"
                min="0"
                value={delivery.inputs.peak}
                onChange={(event) => peakPayChangeHandler(event, index)}
              />
            </div>
            <div className={styles["delivery__control"]}>
              <label>Tip</label>
              <input
                type="number"
                step="0.01"
                min="0"
                value={delivery.inputs.tip}
                onChange={(event) => tipChangeHandler(event, index)}
              />
            </div>
          </div>
        ))}
      </div>
      <div>
        <button type="button" onClick={anotherDeliveryHandler}>
          +Add Another Delivery
        </button>
        <button type="submit">Submit</button>
      </div>
    </form>
  );

  return (
    <div>
      <h1>Add Shift Entry</h1>
      {form}
    </div>
  );
};

export default AddShiftEntry;
