import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import withAWSAuth from "./hoc/withAWSAuth/withAWSAuth";
import { DasherDataContextProvider } from "./store/DasherDataContext";
import UtilityBar from "./components/UI/UtilityBar/UtilityBar";
import Overview from "./containers/Overview/Overview";
import ListView from "./containers/ListView/ListView";
import Sidebar from "./components/UI/Sidebar/Sidebar";
import AddShiftEntry from "./containers/AddShiftEntry/AddShiftEntry";
import PageNotFound from "./containers/PageNotFound/PageNotFound";

function App() {
  return (
    <Router>
      <div className={`body-wrapper`}>
        <Sidebar />
        <DasherDataContextProvider>
          <div className="bodyWrapper">
            <UtilityBar />
            <Routes>
              <Route path="/add-shift-entry" element={<AddShiftEntry />} />
              <Route path="/order-list-view" element={<ListView />} />
              <Route path="/" element={<Overview />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </DasherDataContextProvider>
      </div>
    </Router>
  );
}

export default withAWSAuth(App);
