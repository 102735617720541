import React from "react";
import styles from "./ChartWrapper.module.css";

const ChartWrapper = (props) => {
  return (
    <div className={styles.chartWrapper}>
      <div className={styles.chartLabel}>{props.label}</div>
      {props.children}
    </div>
  );
};

export default ChartWrapper;
