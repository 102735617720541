import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import SidebarTab from "./SidebarTab";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sidebar = () => {
  let currentTab;
  switch (window.location.pathname) {
    case "/order-list-view":
      currentTab = "listView";
      break;
    case "/add-shift-entry":
      currentTab = "addEntry";
      break;
    case "/":
      currentTab = "overview";
      break;
    default:
      currentTab = null;
  }
  const [activeTab, setActiveTab] = useState(currentTab);

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.headerBrand}>
        <Link to="/" onClick={() => tabClickHandler("overview")}>
          DasherBoard
        </Link>
      </div>
      <SidebarTab
        label="Overview"
        linkTo="/"
        onClick={() => tabClickHandler("overview")}
        active={activeTab === "overview"}
      />
      <SidebarTab
        label="Delivery List View"
        linkTo="/order-list-view"
        onClick={() => tabClickHandler("listView")}
        active={activeTab === "listView"}
      />
      <SidebarTab
        label="Add Shift Entry"
        linkTo="/add-shift-entry"
        onClick={() => tabClickHandler("addEntry")}
        active={activeTab === "addEntry"}
      />
    </div>
  );
};

export default Sidebar;
