import React from "react";
import styles from "./FormField.module.css";

const NumericFormField = (props) => {
  return (
    <div className={styles["form__control"]}>
      <label>{props.label}</label>
      <input
        className={props.fieldState.hasError ? styles["invalid-input"] : ""}
        type="number"
        step="0.01"
        min="0"
        value={props.fieldState.value}
        onChange={props.fieldState.changeHandler}
        onBlur={props.fieldState.blurHandler}
      />
      {props.fieldState.hasError ? <p>{props.errorMsg}</p> : ""}
    </div>
  );
};

export default NumericFormField;
