import React, { useContext } from "react";
import { DasherDataContext } from "../../store/DasherDataContext";
import ListItem from "../../components/ListItem/ListItem";
import styles from "./ListView.module.css";

const ListView = () => {
  const dasherDataCtx = useContext(DasherDataContext);

  let getShiftList = () => dasherDataCtx.dasherData.map((shift) => (
    <ListItem key={shift.order_id} shiftData={shift} />
  ));

  let shiftListHeaderRow = (
    <div className={styles.shiftDetailsHeaderRow}>
        <span>Date</span>
        {/* <span>Start Location</span>
        <span>End Location</span> */}
        <span>Miles</span>
        <span>Total Hours</span>
        <span>Active Hours</span>
        <span>Hourly Rate</span>
        <span>Gross</span>
      </div>
  )

  return (
    <div className={styles.deliveryList}>
      <h1>Delivery List View</h1>
      {shiftListHeaderRow}
      {dasherDataCtx.dasherData ? getShiftList() : ''}
    </div>
  );
};

export default ListView;
