import React, { useState, useEffect } from "react";
import axios from "axios";

const DasherDataContext = React.createContext({
  dasherData: [],
  dasherAnalytics: {
    hourlyLow: 0,
    hourlyHigh: 0,
    hourlyAvg: 0,
    mileageSum: 0,
    totalHoursSum: 0,
    activeHoursSum: 0,
    basePaySum: 0,
    peakPaySum: 0,
    tipPaySum: 0,
    grossSum: 0,
  },
});

export const DasherDataContextProvider = (props) => {
  const [dasherData, setDasherData] = useState(null);
  const [dasherAnalytics, setDasherAnalytics] = useState(null);

  const formatData = (data) => {
    let dasherShiftItems = data.Items;
    let analyticsMetrics = {
      hourlyLow: data.Items[0].hourly,
      hourlyHigh: data.Items[0].hourly,
      hourlyAvg: 0,
      mileageSum: 0,
      totalHoursSum: 0,
      activeHoursSum: 0,
      basePaySum: 0,
      peakPaySum: 0,
      tipPaySum: 0,
      grossSum: 0,
    };

    let shiftEntries = [];
    for (const shiftEntryIndex in dasherShiftItems) {
      let shiftEntry = dasherShiftItems[shiftEntryIndex];
      analyticsMetrics.mileageSum += shiftEntry.miles;
      analyticsMetrics.totalHoursSum += shiftEntry.total_hours;
      analyticsMetrics.activeHoursSum += shiftEntry.active_hours;
      analyticsMetrics.grossSum += shiftEntry.gross;
      if (shiftEntry.hourly < analyticsMetrics.hourlyLow) {
        analyticsMetrics.hourlyLow = shiftEntry.hourly;
      }
      if (shiftEntry.hourly > analyticsMetrics.hourlyHigh) {
        analyticsMetrics.hourlyHigh = shiftEntry.hourly;
      }
      for (const delivery in shiftEntry.deliveries) {
        let currentDelivery = shiftEntry.deliveries[delivery];
        analyticsMetrics.basePaySum += currentDelivery[1];
        analyticsMetrics.peakPaySum += currentDelivery[2];
        analyticsMetrics.tipPaySum += currentDelivery[3];
      }
      shiftEntries.push(shiftEntry);
    }

    shiftEntries.sort(function (a, b) {
      // Turn strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.date) - new Date(a.date);
    });

    analyticsMetrics.hourlyAvg =
      analyticsMetrics.grossSum / analyticsMetrics.totalHoursSum;

    // for(const metric in analyticsMetrics) {
    //   analyticsMetrics[metric] = Math.round(analyticsMetrics[metric] * 100) / 100;
    // }

    return [shiftEntries, analyticsMetrics];
  };

  const fetchData = async () => {
    let data = await getDasherData();
    let [shiftData, analytics] = formatData(data);
    setDasherData(shiftData);
    setDasherAnalytics(analytics);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getDasherData = async () => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.get(
        "https://api.nnmcdonald-portfolio.com/dasherboard",
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  return (
    <DasherDataContext.Provider
      value={{
        dasherData: dasherData,
        dasherAnalytics: dasherAnalytics,
        refreshDasherData: fetchData,
      }}
    >
      {props.children}
    </DasherDataContext.Provider>
  );
};

export { DasherDataContext };
